@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

html {
  font-size: 16px;
}

body {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #6a6a6c;
}

h1 {
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  font-weight: 900;
  color: #464749;
}

b,
strong {
  font-weight: 500;
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.show-in-map {
  display: none;
}

.close-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1000;
  cursor: pointer;
}

.form-control {
    padding: 1rem 1.25rem;
    background: none;
    border: 1px solid #000;
    border-top: none;
    border-right: none;
    border-left: none;
    border-radius: 0;
    height: auto;
    line-height: 1.1875;
}

.form-control::placeholder,
.react-select__placeholder {
  color: #6a6a6c !important;
}

.react-select__control {
    background: none !important;
    min-height: 53px;
    border-color: #000 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.react-select__control--is-focused {
    box-shadow: none !important;
    border-color: #b40022 !important;
}

.card-title strong {
  font-size: 1.5rem;
  padding: 0;
  line-height: 29px;
  font-weight: 500;
  color: #2a2a2a;
  margin-right: 0.5rem;
}

strong {
  color: #2a2a2a;
}

.card-text {
  font-size: 0.875rem;
}
.card-text .icon-image {
  height: 0.875rem;
  margin-right: 0.875rem;
}

.btn {
  line-height: 19px;
  font-size: 16px;
  padding: 16px 20px;
  border-radius: 0;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-danger {
  background-color: #b40023;
  border: 1px solid #b40023;
}

.map-marker {
  /* display: block;
  height: 1rem;
  width: 1rem;
  background-color: #fff;
  border: 0.25rem solid #1a1a1a;
  border-radius: 100%;
  content: " ";
  box-shadow: 0 0 1px 1px #fff; */
  position: absolute;
  bottom: 0;
  left: -15px;
  display: block;
  width: 30px;
  height: 42px;
  cursor: pointer;
}

.map-marker img {
  width: 30px;
  height: 42px;
  position: absolute;
}

.map-marker.selected {
  left: -1rem;
  width: 2rem;
  height: 2rem;
  z-index: 1200;
}

.info-window {
  width: 300px;
  max-width: 300px;
  position: absolute;
  bottom: 5.25rem;
  left: calc(calc(-300px / 2));
  z-index: 1000;
  box-shadow: 0px 10px 20px #00000033;
}

.info-window .arrow {
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 1rem;
  width: 1rem;
  position: absolute;
  bottom: -0.5rem;
  left: calc(calc(300px / 2) - 0.5rem);
  transform: rotate(45deg);
}

.circle-pin {
  display: block;
  content: " ";
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  background-color: #b40023;
  box-shadow: 0px 0px 0px 30px #b4002333;
}

.salon-list .card.active {
  background-color: #000;
}

.salon-list .card.active .card-text,
.salon-list .card.active strong {
  color: #fff;
}

.icon-image {
  display: inline-block;
  height: 1.5rem;
}

.salon-list {
  height: 500px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.salon-list .card:not(.active) .show-in-map {
  display: block;
}

.salon-map {
  min-height: 375px;
  height: 100vw;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
}

.search-options {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.salon-search-form {
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1100;
  position: relative;
}

.salon-list .card {
  border-radius: 0;
  border-bottom: 0;
}

.salon-list .card:last-of-type {
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.card-text .icon-image {
  margin-top: 0.2rem;
}

.salon-list .card-body {
  padding: 1.5rem 1.25rem;
}

.show-in-map {
  padding: 0;
}

.show-in-map img {
  display: inline-block;
}

.show-in-map span {
  display: none;
}
table {
  font-size: 0.875rem;
}

table .form-control {
  font-size: 0.875rem;
}

.search-form-control .col {
  flex: 0 0 100%;
  max-width: 100%;
}

.react-select__indicator {
  padding: 16px !important;
}

.react-select__indicator-separator {
    display: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.arrow-down {
  width: 0px;
  height: 0px;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.625rem solid #6a6a6c;
}

.card-text {
  line-height: 17px;
  font-size: 0.875rem;
}

.react-select__value-container {
  padding-left: 1.25rem !important;
}

.gmnoprint > div,
.gm-fullscreen-control,
.gm-svpc {
  border-radius: 8px !important;
}

.gm-uselocation {
  background: #fff none repeat scroll 0% 0%;
  display: block;
  border: 0px none;
  margin: 0px;
  margin-right: 10px;
  padding: 0px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  width: 40px;
  height: 40px;
  outline: currentcolor none medium;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.gm-uselocation-icon {
  background-image: url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png);
  background-size: 200px 20px;
  background-position: 0 0;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .salon-list {
    order: 0;
    height: calc(100vh - 192px);
    border-bottom: none;
  }

  .salon-map {
    order: 2;
    height: calc(100vh - 192px);
  }

  .salon-map:not(.show) {
    display: block;
  }

  .search-options {
    margin-bottom: 1rem;
    flex-direction: column;
  }

  .search-options .form-inline:last-child {
    margin-top: 0.5rem;
  }

  h1 {
    font-size: 2rem;
  }

  .salon-search-form [class^="col"] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .salon-list .card:not(.active) .show-in-map {
    flex: 0 0 121px;
  }

  .show-in-map {
    padding: 0.5rem 1rem;
  }

  .show-in-map img {
    display: none;
  }

  .show-in-map span {
    display: inline-block;
  }

  .search-form-control .col {
    flex: 1 1 33%;
  }

  .search-form-control .col:last-child {
    flex: 0 1 180px;
    max-width: 180px;
  }

  .react-select__value-container {
    max-height: 49px;
    overflow-y: auto !important;
  }
}

@media (min-width: 1280px) {
  .search-options {
    flex-direction: row;
  }

  .search-options .form-inline:last-child {
    margin-top: 0;
  }
  .salon-list {
    position: absolute;
    z-index: 1000;
    max-width: 375px;
    flex: 0 0 375px;
    left: 8.333%;
    box-shadow: 0px 3px 6px #00000029;
  }

  .salon-map {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (min-width: 1600px) {
  .salon-list {
    max-width: 475px;
    flex: 0 0 475px;
  }
}

.form-control:focus {
    box-shadow: none;
    border-color: #b40022;
}